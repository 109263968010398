


















































































































































.tox-tinymce{
  min-height: 60vh;
}
